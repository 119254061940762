import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogHaircare() {
	
	return (
		<Article
			title='My experience with selection of hair care products'
			takeaway_title='Avoid hair care products with any of the following ingredients'
			page_id='Blog - Haircare'
			read_time='5 mins'
			author='Brij Bhushan'
			image1='/influence.jpg'
			image1_description='Various factors that influenced my choice of hair care products'
			image2='/imply.jpg'
			image2_description='Products with harmful chemicals that I was earlier using costed Rs 1530, whereas my new set of the healthy alternatives cost Rs 652'
			toggle_article='Solution'
			toggle_url='/article/soln_haircare'
			related_article='Solution - Haircare'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						When I look back, there have been very interesting reasons for me to select a shampoo. When I selected my first shampoo the criteria was that my elder brother was using it. When I made my second choice, it was because it claimed to have herbal extracts. Next time, I was influenced by the promise to give dandruff-free hair with a smooth texture. Lastly, when I was earning well, it was recommended by the leading trichology centre of the country. 
					</p>
					<p>
						TV advertisements are designed to exaggerate a small piece of the product, to create a perception that the product will do wonders to your hair, and they use plenty of computer graphics to blow this perception out of proportion. Sometimes, hair care products are positioned as herbal ones by making large visuals of herbs, on the packaging & in promotions, whereas those herbs are a small portion of the ingredients & they remain silent about the harsh chemicals used in the preparations. Similarly, other products position themselves as anti-dandruff because they have an anti-fungal ingredient, even though their use in the long run causes dryness & inflammation, which increases dandruff further. On the other hand, there are few clinics who claim to be experts in hair care but their advice is biased towards selling their own products. At times, these expensive inhouse products sold by these clinics carry more harmful chemicals than the ones available in the market.
					</p>
					<p className='article_para'>
						Therefore, whether my criteria was a naive one or based on so-called experts' opinions, I always ended up with dry hairs and itchy-skin on my scalp. The condition of my hair continued to deteriorate as I moved from my 30s to 40s. It was then I started researching on health-related topics. That’s when I found out that my hair shampoo, which I have been using for 10 years contains sulphates and my hair conditioning serum which I have been using for the last 2 years has silicones. No surprise that my hairs were turning dry & difficult to manage year-on-year and it has been a common thing to have acne eruption on my dry scalp. To learn about my journey from identifying my dandruff problem & solving it, refer to my blog <Link to='/article/blog_dandruff'>here</Link>. To learn about my experience of solving my hairloss problem, refer to my blog <Link to='/article/blog_hairloss'>here</Link>
					</p>
					<p className='article_para'>
						So finally, I switched to a shampoo with following ingredients: Harad, Coconut, Shikakai, Nagarmotha, Brahmi, Neem, Bawachi, Amla, Arjun, Bhringraj, Rose, Jamun Chal, Bahera, Belpatra, Balchar, Anantmool, Methi, Tulsi, Reetha (foaming agent), Sodium Benzoate (preservative).

					</p>
					<p className='article_para'>
						and a hair conditioner with the following ingredients: Indian water lily, Gotukola, Bhringraj ext., Vibhitaki ext., Mulethi ext., Mustaka, Indian sarsaparilla, Aloe vera leaf juice, Cold pressed wheat germ oil, Cold pressed jojoba oil, Aqua, coconut oil derivatives, Cold pressed moringa seed oil, Cetearyl Olivate, Cold pressed avocado oil, Vegetable glycerine, Octyldodecanol, Sorbitan olivate, Shea butter, Caprylyl glycol, Potassium sorbate, Gluconolactone, Sodium gluconate, Ethylhexylglycerin, Natural preservative blend of herbs and essential oils.

					</p>
					<p className='article_para'>
						I have been using these two sulfate-free & silicone-free products for the last 2 years along with a healthy diet and finally I believe I have good manageable healthy hair and an inflammation-free scalp.
					</p>
					<p className='article_para'>
						You will be surprised to know that all the leading shampoos and conditioners have several harmful chemicals. Making the right choice depends on your awareness level and the right choice may not be an expensive one. Do take a look at the list of the ingredients of a hair care product before purchasing one. One can easily find the ingredients on internet by searching for the name of the product. To have details about why these chemicals are used and why they are harmful, you can refer to the other article <Link to='/article/soln_haircare'>here</Link>.
					</p>

					{/*<h3 className='article_heading'>Clinic Plus Health Shampoo (Strong & long)</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, <font color='red'>Sodium Laureth Sulfate, Dimethiconol and TEA-dodecyl-benzene Sulfonate, Cocamidopropyl Betaine</font>, Sodium Chloride, <font color='red'>Perfume, Carbomer</font>, Glycol Distearate, Guar Hydroxypropyl-trimonium Chloride, Sodium Hydroxide, Lysine Monohydrochloride, Citric Acid, <font color='red'>Cocamide MEA, DMDM Hydantoin, PEG-45M</font>, Mica & Titanium Dioxide, <font color='red'>Disodium EDTA</font>, Hydrolyzed Milk Protein, Niacinamide, Pyridoxine Hydrochloride, Sodium Ascorbyl Phosphate, Tocopheryl Acetate, <font color='red'>Methyl-chloro-isothiazolinone and Methyl-isothiazolinone</font>, Benzyl Benzoate, Benzyl Salicylate, Butylphenyl Methylpropional, Coumarin, Hexyl Cinnamal, Linalool, CI 42090.
					</p>
					<h3 className='article_heading'>Head & shoulders anti-dandruff Shampoo</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, <font color='red'>Sodium Laureth Sulphate, Sodium Lauryl Sulphate</font>, Sodium Xylenesulfonate, <font color='red'>Cocamidopropyl Betaine</font>, Glycol Distearate, Sodium Citrate, <font color='red'>Dimethiconol</font>, Sodium Chloride, <font color='red'>Fragrance</font>, Piroctone Olamine, <font color='red'>Dimethicone</font>, Citric Acid, Sodium Benzoate, <font color='red'>TEA-dodecyl-benzene Sulfonate</font>, Guar Hydroxypropyl-trimonium Chloride, <font color='red'>Trideceth-10, Tetrasodium EDTA</font>, Linalool, Hexyl Cinnamal, Benzyl Salicylate, Limonene, <font color='red'>Propylene Glycol, Methyl-chloro-isothiazolinone, Methyl-isothiazolinone</font>.
					</p>
					<h3 className='article_heading'>Sunsilk Co-creations Stunning Black Shine Shampoo</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, <font color='red'>Sodium Laureth Sulphate, Dimethiconol and TEA-dodecyl-benzene Sulfonate, Cocamide MEA</font>, Sodium Chloride, <font color='red'>Perfume</font>, Lysine Hydrochloride, <font color='red'>Carbomer</font>, Guar Hydroxypropyl-trimonium Chloride, Panthenol, Ethylhexyl Methoxycinnamate, Hydrolyzed Conchiolin Protein (Pearl Extract), Phyllanthus Emblica (Amla) Fruit Extract, Sodium Hydroxide, <font color='red'>Disodium EDTA, DMDM Hydantoin</font>, Mica and Titanium Dioxide, Citric Acid, <font color='red'>Methyl-chloro-isothiazolinone and Methyl-isothiazolinone</font>, CI 77266, Alpha-isomethyl Ionone, Butylphenyl Methyl-propional, Citronellol, Geraniol, Limonene, Linalool, Coumarin.
					</p>
					<h3 className='article_heading'>Dabur Vatika Health Shampoo</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Aqua containing extracts of Shikakai, Henna, Almond, Amla, Hibiscus and Reetha, <font color='red'>Sodium Laureth Sulphate, Cocamide MEA, Dimethiconol, Cocamidopropyl Betaine</font>, Glycol Stearate, Glycerin, <font color='red'>Perfume</font>, Sodium Chloride, <font color='red'>Amodimethicone</font>, Coco Glucoside, Guar Hydroxypropyl-trimonium Chloride, Olive Oil, Lactic Acid, Vitamin E Acetate, Panthenol, <font color='red'>Carbomer, PEG-90M, Disodium EDTA</font>, Sodium Hydroxide, <font color='red'>Methyl-chloro-isothiazolinone, Methyl-isothiazolinone</font>, CI 61565, CI 47000.
					</p>
					<h3 className='article_heading'>L'oreal Paris Extraordinary Clay Shampoo</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, <font color='red'>Sodium Laureth Sulphate, Dimethicone, Cocamidopropyl Betaine</font>, Sodium Chloride, CI 77891, Titanium Dioxide, CI 42090/Blue1, Guar Hydroxypropyl-trimonium Chloride, Mica, Coconut Oil, Sodium Benzoate, Sodium Hydroxide, Sodium Hyaluronate, <font color='red'>Phenoxyethanol, Steareth-6</font>, Acetic Acid, <font color='red'>PEG-100 Stearate</font>, Argilla/Magnesium Aluminium Silicate, <font color='red'>Trideceth-10, Trideceth-3</font>, Salicylic Acid, Limonene, Fumaric Acid, Menthol, Benzyl Alcohol, Benzyl Salicylate, Linalool, Montmorillonite, <font color='red'>Amodimethicone</font>, Kaolin, <font color='red'>Fragrance, Carbomer</font>, Citric Acid, Hexylene Glycol, Hexyl Cinnamal, Glycerin.
					</p>
					<h3 className='article_heading'>TRESemme Keratin Smooth Shampoo</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, <font color='red'>Sodium Laureth Sulfate, Dimethiconol and Trideceth-10 and TEA-dodecyl-benzene Sulfonate, Cocamidopropyl Betaine</font>, Sodium Chloride, <font color='red'>Perfume, Amodimethicone and Cetrimonium Chloride and Trideceth-12, Carbomer</font>, Glycol Distearate, Guar Hydroxypropyl-trimonium Chloride, Mica & Titanium Dioxide, Sodium Hydroxide, Citric Acid, <font color='red'>DMDM Hydantoin, Disodium EDTA</font>, Hydrolyzed Keratin, Argania Spinosa (Argan Oil) Kernel Oil, <font color='red'>Methyl-chloro-isothiazolinone and Methyl-isothiazolinone</font>, Linalool, Benzyl Salicylate, Citronellol, Alpha-isomethyl ionone, Hexyl Cinnamal.
					</p>
					<h3 className='article_heading'>Richfeel Scalp Cleanser (with Saw Palmetto) - Shampoo</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Aqua, Saw Palmetto, <font color='red'>Dimethicone Copolyol, Sodium Lauryl Ether Sulphate, Cocomono (Cocamide MEA), Cocodie (Cocamide DEA), Ethylene Glycol Monostearate (PEG stearate), Cocamidopropyl Betaine, Preservatives.</font>
					</p>
					<h3 className='article_heading'>Dove Nutritive Solutions - intense repair conditioner</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, Cetearyl Alcohol, <font color='red'>Dimethicone and Amodimethicone and PEG-7 Propylheptyl Ether and Cetrimonium Chloride, Behentrimonium Chloride</font>, Glycerin, <font color='red'>Perfume, Disodium EDTA</font>, Sodium Chloride, Gluconolactone, Trehalose, <font color='red'>Sodium Sulphate</font>, Helianthus Annuus (Sunflower) Seed Oil, <font color='red'>Methyl-chloro-isothiazolinone and Methyl-isothiazolinone</font>, Alpha-isomethyl ionone, Citronellol, Geraniol, Linalool.
					</p>
					<h3 className='article_heading'>TRESemme Climate Protection Conditioner</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, Cetearyl Alcohol, <font color='red'>Dimethicone and Amodimethicone and PEG-7 Propylheptyl Ether and Cetrimonium Chloride, Behentrimonium Chloride, Perfume</font>, Stearamidopropyl Dimethylamine, Sodium Chloride, Lactic Acid, <font color='red'>Disodium EDTA</font>, Lysine Hydrochloride, Ethylhexyl Methoxycinnamate, Hydrolyzed Keratin, Panthenol, Olea Europaea (Olive) Fruit Oil, <font color='red'>Methyl-chloro-isothiazolinone and Methyl-isothiazolinone</font>, Linalool, Benzyl Salicylate, Citronellol, Alpha-isomethyl Ionone, Hexyl Cinnamal.
					</p>
					<h3 className='article_heading'>L'oreal Paris SerieExpert Lipidium Absolut Repair - Conditioner</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						Water, Cetearyl Alcohol, <font color='red'>Behentrimonium Chloride</font>, Candelilla Cera / Candelilla Wax, <font color='red'>Amodimethicone</font>, Cetyl Esters, Isopropyl Alcohol, Glycerin, <font color='red'>Phenoxyethanol, Trideceth-6</font>, Lactic Acid, Linalool, Chlorhexidine Digluconate, <font color='red'>Cetrimonium Chloride</font>, Sodium Hydroxide, Limonene, Hexyl Cinnamal, 2-Oleamido-1,3-Octadecanediol, <font color='red'>Polyquaternium-51</font>, CI 19140/Yellow 5, CI 15985/Yellow 6, <font color='red'>Fragrance</font> C214997/1.
					</p>
					<h3 className='article_heading'>L'oreal Paris Smooth Intense Instant Smoothing Serum (unmanageable, Dry Hair)</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						<font color='red'>Cyclopentasiloxane, Dimethiconol, Hydroxycitronellal</font>, Argania Spinosa Kernel Oil, Camelina Sativa Seed Oil, Limonene, Linalool, Benzyl Alcohol, Benzyl Salicylate, Alpha-isomethyl ionone, Geraniol, Butylphenyl Methylpropional, Citronellol, <font color='red'>BHT</font>, Hexyl Cinnamal, Amyl Cinnamal, <font color='red'>Fragrance</font>.
					</p>
					<h3 className='article_heading'>Richfeel Cutishine Hair Repair - Conditioning Serum</h3>
					<h6 className='ingredients'>Ingredients</h6>
					<p className='article_para'>
						<font color='red'>Cyclomethicone, Dimethiconal</font>
					</p>*/}
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: 'Sodium Lauryl Sulfate (SLS)',
	},
	{
		takeaway: 'Sodium Laureth Sulfate (SLES)',
	},
	{
		takeaway: 'MEA, DEA & TEA chemicals',
	},
	{
		takeaway: 'PEG-n',
	},
	{
		takeaway: 'Carbomer',
	},
	{
		takeaway: 'Sodium Sulphate',
	},
	{
		takeaway: 'Tetrasodium EDTA',
	},
	{
		takeaway: 'Disodium EDTA',
	},
	{
		takeaway: 'Propylene Glycol',
	},
	{
		takeaway: 'Dimethicone',
	},
	{
		takeaway: 'Polyquaternium-n',
	},
	{
		takeaway: 'Behentrimonium Chloride',
	},
	{
		takeaway: 'Methyl-chloro-isothiazalinone (MCI)',
	},
	{
		takeaway: 'Methyl-isothiazalinone (MI)',
	},
	{
		takeaway: 'DMDM Hydantoin',
	},
	{
		takeaway: 'Cetrimonium Chloride',
	},
	{
		takeaway: 'BHT',
	},
	{
		takeaway: 'Hydroxycitronellal',
	},
];

const RelatedArticles = [
	{
		title: 'Solution - Haircare',
		path: '/article/soln_haircare',
	},
	{
		title: 'Blog - Dandruff',
		path: '/article/blog_dandruff',
	},
	{
		title: 'Blog - Hairloss',
		path: '/article/blog_hairloss',
	},
];